import React from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import ProjectDetails2Header from "components/Project-details2-header/project-details2-header";
import ProjectIntroductionAtsap from "components/Project-introduction/project-introduction-atsap";
import ProjectGalleryAtsap from "components/Project-gallery/project-gallery-atsap";
import ProjectDescription from "components/Project-description/project-description";
import ProjectDateAtsap from "data/atsap-details.json";
import DarkTheme from "layouts/Dark";

const Atsap = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <div className="wrapper">
        <ProjectDetails2Header projectHeaderData={ProjectDateAtsap} />
        <ProjectIntroductionAtsap projectIntroductionData={ProjectDateAtsap.intro} />
        <ProjectGalleryAtsap />
        <ProjectDescription projectDescriptionData={ProjectDateAtsap.description} />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Air Traffic Safety Action Program (ATSAP)</title>
    </>
  )
}

export default Atsap;
